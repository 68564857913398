// export {} // Fixes "Cannot redeclare block-scoped variable" warning with parcel

import Typed from 'typed.js'
const log = console.log

// Typing animation. Script tag is in html
// https://www.youtube.com/watch?v=tcskp-ncN0I&t=516s
let typed: any = new Typed('.typing', {
  strings: [
    'Web / Software Developer',
    'Paragliding Pilot',
    'Drone Pilot',
    'CAD Technician',
    'Amatuer Welder',
    'Tinkerer',
  ],
  typeSpeed: 100,
  backSpeed: 60,
  loop: true,
})

// Hamburger Menu
// let menuIcon = document.querySelector('.hamburger-menu')
// let aside = document.querySelector('.aside')

// menuIcon.addEventListener('click', () => {
//   console.log('Menu Icon Clicked')
//   aside.classList.toggle('open')
// })
